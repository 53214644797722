import React, { useState } from 'react';
import { Select } from 'antd';
import { ErrorMessage } from 'formik';

// Define a generic type T
export type OptionType = {
    value: number | string;
    label: string;
};

interface Props<T> {
    disabled?: boolean | undefined;
    mode?: 'multiple' | 'tags';
    onSearch?: (v: string) => void;
    valueFunc?: T;
    onChange?: (
        val: T
    ) => void;
    options: OptionType[] | [] | undefined | any;
    placeholder: string;
    required?: boolean;
    label?: string,
    name?: string,
    clearable?: boolean;
    onClear?: () => void,
    customOptionRender?: (d: any) => any;
    defaultValue?: T,
    className?: string;
    onDeselect?: any;
    style?: any
}

const LoomSelect = <T,>({
    disabled,
    mode,
    onSearch,
    valueFunc,
    onChange,
    options,
    placeholder,
    label,
    required = false,
    name = "",
    clearable = false,
    customOptionRender,
    defaultValue,
    onClear,
    className,
    onDeselect,
    style,
}: Props<T>) => {

    const handleChange = (value: T) => {
        if (onChange)
            onChange(value);
    };
    const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | null>(null);

    const handleOnChange = (newValue: string) => {
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        const newTimeoutRef = setTimeout(() => {
            if (onSearch)
                onSearch(newValue)
        }, 500);
        setTimeoutRef(newTimeoutRef);
    };
    const handleOnClear = () => {
        if (onClear)
            onClear();
    }
    return (
        <div className="flex gap-2 flex-col w-full">
            {label && <div>
                <label className={`form-label ${required ? 'required' : ''}`}>
                    {label}
                </label>
            </div>}
            <Select
                style={{ ...style }}
                onDeselect={onDeselect}
                defaultValue={defaultValue}
                allowClear={clearable}
                disabled={disabled}
                mode={mode}
                placeholder={placeholder}
                filterOption={false}
                onSearch={handleOnChange}
                onChange={handleChange}
                options={options}
                value={valueFunc}
                showSearch
                className={`h-input ${className}`}
                optionRender={customOptionRender}
                onClear={handleOnClear}
            />
            <ErrorMessage name={name} className="text-red-500">
                {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
            </ErrorMessage>
        </div>
    );
};

export default LoomSelect;
