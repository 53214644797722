import { createContext } from 'react';
import { LeaveOptions } from '../../../@types/FetchOptions';
import { Beneficiary } from '../../../api-requests/Projects';
import { Trip, TripApproval } from '../../../@types/Trip';


export interface ITripContext {
    trips: Trip[];
    setTrips: (d: Trip[]) => void;
    getTrip: (params: LeaveOptions) => void;
    tripsTotal: number;
    loading: boolean;
    handleDeleteTripById: (d: number) => void;
    getBeneficiaries: (projectId: number) => Promise<Beneficiary[] | undefined>;
    beneficiaries: Beneficiary[];
    tripApprovals: TripApproval[];
    setTripApprovals: (d: TripApproval[]) => void;
    getApprovalTrips: (params: LeaveOptions) => void;

    tripApprovalTotal: number;
}

const TripContext = createContext<ITripContext | undefined>(
    undefined,
);

TripContext.displayName = 'Trip';

export default TripContext;
