import { Spin } from 'antd';
import { FC } from 'react';

type Props = {
  spin?: boolean;
  text?: string;
};

const Loader: FC<Props> = ({ spin, text }) => {
  return (
    <div className="text-center">
      {/* <Spinner animation="border" role="status"></Spinner> */}
      {spin && (
        // <div className="p-10 flex flex-row items-center justify-center">
        <div className="p-10 ">
          <Spin size="large" />
          {text && <div className="text-center small mt-6">{text}</div>}
        </div>
      )}
      {!spin && text && <div className="text-center small mt-4">{text}</div>}
    </div>
  );
};

export default Loader;
