import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { mockedEmployees } from '../mock/Employees';
import { CommonListResponse } from '../@types/Response';
import { Employee, EmployeeSimple, EmployeeValidationSchema, FetchEmployeesOptions, RelatedEmployeeListValidationSchema, TeamListSchema } from '../@types/Employee';
import { Team } from '../@types/Team';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const FETCH_EMPLOYEES = `${API_URL}/employees`;
export const FETCH_RELATED_EMPS = `${API_URL}/employees/people`;
export const FETCH_TEAMS = `${API_URL}/teams/all`
export const CREATE_TEAM = `${API_URL}/teams`;


export const createTeamApi = async (values: Team) => {
  try {
    const response = await axios.post<''>(CREATE_TEAM, values);
    return response.data;
  } catch (error) {
    console.error('Error creating team:', error);
    throw error;
  }
}
export const fetchEmployees = async (
  { includeInactive, orgId, projectId, fetchAllEmployees, searchTerm }: FetchEmployeesOptions = { includeInactive: false, projectId: 0, fetchAllEmployees: true, searchTerm: '' },
) => {
  try {
    const params = new URLSearchParams({
      includeInactive: includeInactive!.toString(),
      orgId: orgId ? orgId.toString() : '0',
      projectId: projectId ? projectId?.toString() : '0',
      fetchAllEmployees: fetchAllEmployees ? String(fetchAllEmployees) : "true",
      searchTerm: searchTerm ? searchTerm : '',
    });

    const response = await axios.get<CommonListResponse<Employee>>(
      `${FETCH_EMPLOYEES}?${params.toString()}`,
    );
    // await EmployeeValidationSchema.validate(response.data.Results);
    return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export const fetchRelatedPeople = async ({ includeInactive, searchTerm }: FetchEmployeesOptions = { includeInactive: false, pageSize: 10, projectId: 0 }) => {
  try {
    const params = new URLSearchParams({
      searchTerm: searchTerm ? searchTerm : '',
      includeInactive: includeInactive!.toString(),
      pageSize: "500",
    });
    const response = await axios.get<CommonListResponse<EmployeeSimple>>(
      `${FETCH_RELATED_EMPS}?${params.toString()}`,
    );
    // await RelatedEmployeeListValidationSchema.validate(response.data.Results);

    return response.data;
  } catch (error) {
    console.error('Error fetching related emps');
    throw error;
  }
};
export const fetchEmployeesPresence = async (
  date: string | Date,
  searchTerm: string,
) => {
  try {
    return mockedEmployees.filter((emp) => emp.FullName.includes(searchTerm));
  } catch (error) {
    console.error('Error fetching employees for ', date, error);
    throw error;
  }
};

export const deleteTeamId = async (id: number) => {
  try {
    const response = axios.delete(`${CREATE_TEAM}?id=${id}`);
    return response;
  } catch (err) {
    console.error('Error deleting team id', err);
    throw err;
  }
}
export const fetchTeams = async ({
  pageSize = 10,
  searchTerm = '',
  status = '',
  personIds = '',
  includeInactive = false,
  pageIndex = 0,
}) => {
  try {
    const params = new URLSearchParams({
      searchTerm: searchTerm ? searchTerm : '',
      includeInactive: includeInactive!.toString(),
      status,
      pageIndex: pageIndex.toString(),
      pageSize: pageSize.toString(),
      personIds
    });
    const response = await axios.get<CommonListResponse<Team>>(
      `${FETCH_TEAMS}?${params.toString()}`,
    );
    // await TeamListSchema.validate(response.data.Results);
    return response.data;
  } catch (error) {
    console.error('Error fetching teams');
    throw error;
  }
}

export interface EmployeeWithAvatar extends Employee {
  avatarColour: string;
}
