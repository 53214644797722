import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Tooltip } from 'antd';
import { isLightColour } from '../../utils/colourFunctions';
import { hashCode, intToRGB } from '../../utils/generateColors';
import { EmployeeWithAvatar } from '../../api-requests/Employees';
import LoomAvatar from '../../utils/Avatar/Avatar';
import { AvatarSize } from 'antd/es/avatar/AvatarContext';

type Props = {
    cell: EmployeeWithAvatar[] | any;
    avatarSize?: "large" | "small" | "default" | undefined
};

const AvatarGroupCell: React.FC<Props> = ({ cell, avatarSize = 'default' }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [maxVisibleAvatars, setMaxVisibleAvatars] = useState(4);
    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setMaxVisibleAvatars(3); // Adjust to ensure space for '+n' avatar
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const overflowCount = cell.length - maxVisibleAvatars;

    return (
        <div ref={containerRef} style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Avatar.Group size="small">
                {cell.slice(0, maxVisibleAvatars).map((cl: any, i: number) => (
                    <LoomAvatar size={avatarSize} key={`av-${i}`} fullName={cl.FullName} doubleLetters={[cl.FirstName[0], cl.LastName[0]]} />
                ))}
                {overflowCount > 0 && (
                    <Tooltip title={
                        cell.slice(maxVisibleAvatars).map((c: any, i: number) => (
                            <div key={i} className="row text-left">
                                <span>{c.FullName}</span>
                            </div>
                        ))
                    }>
                        <Avatar className='bg-primary' size={avatarSize}>+{overflowCount}</Avatar>
                    </Tooltip>
                )}
            </Avatar.Group>
        </div>
    );
};

export default AvatarGroupCell;
