import { createContext } from 'react';
import { ParticipantReports, ProjectExtended, ProjectReporting, Report } from '../../../../@types/Project';
import { FetchProjectsOptions } from '../../../../api-requests/Projects';

export interface IProjectContext {
    getProjects: (d: any) => void;
    projects: ProjectExtended[];
    totalProjects: number;
    loading: boolean;
    projectReportings: ProjectReporting[];
    totalReportings: number;
    setProjects: (d: ProjectExtended[]) => void;
    setParticipantsReports: (d: ParticipantReports[]) => void;
    setPeriodicReports: (d: ProjectReporting[]) => void;
    getProjectReportings: (params: FetchProjectsOptions) => void;
    setProjectReportings: (d: ProjectReporting[]) => void;
    periodicReports: any[];
    periodicReportsTotal: number;
    getPeriodicReports: (params: FetchProjectsOptions) => void;
    getParticipantReports: (params: FetchProjectsOptions) => void;
    participantReports: ParticipantReports[];
    participantReportsTotal: number;
    getReportingPeriods: (id: number) => void;
    reportingPeriods: Report[];
    reportingPeriodsTotal: number;
    activeProject: number;
    setActiveProject: (d: number) => void;
    activeRP: number;
    setActiveRP: (d: number) => void;
}

const ProjectContext = createContext<IProjectContext | undefined>(
    undefined,
);

ProjectContext.displayName = 'Project';

export default ProjectContext;
