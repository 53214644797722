import { useEffect, useRef, useState } from 'react';
import {
  PieChartOutlined,
  UnorderedListOutlined,
  PrinterOutlined,
  CalendarOutlined,
  WalletOutlined,
  SafetyOutlined,
  CarryOutOutlined,
  PlusCircleOutlined,
  ClockCircleOutlined,
  HistoryOutlined,
  FieldTimeOutlined,
  CarOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ProjectOutlined,
  BookOutlined,
  ContactsOutlined,
  FormOutlined,
  BugFilled,
  MenuOutlined,
  ToTopOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Layout, Menu, Typography, Grid } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SidebarLogo } from './SidebarLogo';
import { useAuth } from '../../features/auth';
import SimpleBar from 'simplebar-react';
import CalendarModalForm from '../../features/leave/components/Form/CalendarModalForm';
import { useFeature } from '../../features/feature/FeatureProvider';
import ReportModal from '../../features/report/ReportModal';
import { initLeaveRequest } from '../../utils/FormValidation/LeaveFormValidation';
import {
  MyLeaveContext,
  useLeaveContext,
} from '../../features/leave/provider/GenericLeaveProvider';
import useRemainingHeight from '../../hooks/useRemainingHeight.hook';
import { BalanceProvider } from '../../features/leave/provider/BalanceProvider';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

type Props = {
  collapsed: boolean;
  setCollapsed: (d: boolean) => void;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Sidebar = ({ collapsed, setCollapsed }: Props) => {
  const { xs } = useBreakpoint();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const { allowIf } = useAuth();
  const { showFeature } = useFeature();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [data, setData] = useState(initLeaveRequest);
  const [openReport, setOpenReport] = useState(false);
  const { fetchLeavesFunc } = useLeaveContext(MyLeaveContext);
  const location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === '/' || location.pathname === ''
      ? '/dashboard'
      : location.pathname,
  );

  const topLogoRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const contentHeight = useRemainingHeight([topLogoRef, footerRef]);
  const [menuOpenedKeys, setMenuOpenedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  useEffect(() => {
    // Function to update dimensions
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    //open only the first level of menu if its ony one and has children
    if (menuItems && menuItems.length === 1 && menuItems[0]) {
      if ('children' in menuItems[0]) {
        setMenuOpenedKeys([menuItems[0].key as string]);
      }
    }

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuItems: MenuProps['items'] = [
    allowIf(['Timesheets_ReadWrite', "LeaveRequests_ReadWrite"]) && {
      key: '/dashboard',
      icon: <PieChartOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    showFeature('LEAVES') &&
      (allowIf(['LeaveHistory_View']) || allowIf(['Meetings_Read']))
      ? {
        key: '/apps/leave/calendar',
        icon: <CalendarOutlined />,
        label: <Link to="/apps/leave/calendar">Calendar</Link>,
      }
      : null,
    showFeature('TIMESHEET') && allowIf(['Timesheets_ReadWrite'])
      ? {
        key: 'sub1',
        icon: <FieldTimeOutlined />,
        label: 'Timesheets',
        children: [
          allowIf(['Timesheets_Approve']) && {
            key: '/apps/timesheet/print-timesheet',
            icon: <PrinterOutlined />,
            label: (
              <Link to="/apps/timesheet/print-timesheet">
                Print Timesheet
              </Link>
            ),
          },
          {
            key: '/apps/timesheet/list',
            icon: <UnorderedListOutlined />,
            label: <Link to="/apps/timesheet/list">Timesheet List</Link>,
          },
          {
            key: '/apps/timesheet/list/wp',
            icon: <UnorderedListOutlined />,
            label: (
              <Link to="/apps/timesheet/list/wp">
                Timesheet per Work Package
              </Link>
            ),
          },
        ].filter(Boolean),
      }
      : null,
    showFeature('PROJECTREPORTING') && allowIf(['Projects_Read'])
      ? {
        key: 'projects',
        icon: <BookOutlined />,
        label: 'Projects',
        children: [
          allowIf(['FinancialReporting_Read'])
            ? {
              key: '/apps/projects/report/list',
              icon: <ProjectOutlined />,
              label: (
                <Link to="/apps/projects/report/list">
                  Project Participations
                </Link>
              ),
            }
            : null,
          allowIf(['FinancialReporting_Read'])
            ? {
              key: '/apps/projects/periodic_reports',
              icon: <FormOutlined />,
              label: (
                <Link to="/apps/projects/periodic_reports">
                  Periodic Reports
                </Link>
              ),
            }
            : null,
          allowIf(['FinancialReporting_Approve'])
            ? {
              key: '/apps/projects/participants_reports',
              icon: <FormOutlined />,
              label: (
                <Link to="/apps/projects/participants_reports">
                  Coordinator List
                </Link>
              ),
            }
            : null,
          allowIf(['Projects_Read']) && {
            key: '/apps/projects/list',
            icon: <ClockCircleOutlined />,
            label: <Link to="/apps/projects/list">Project List</Link>,
          },
        ].filter(Boolean),
      }
      : null,
    showFeature('MEETING') &&
      (allowIf(['Meetings_Read']) || allowIf(['Trips_EditAll']))
      ? {
        key: 'projectsevents',
        icon: <ContactsOutlined />,
        label: 'Project Events',
        children: [
          allowIf(['Meetings_Read']) && {
            key: '/apps/projects/meetings/list',
            icon: <HistoryOutlined />,
            label: <Link to="/apps/projects/meetings/list">Meetings</Link>,
          },
          {
            key: '/apps/projects/meetings/trip/list',
            icon: <CarOutlined />,
            label: <Link to="/apps/projects/meetings/trip/list">Trips</Link>,
          },
          allowIf(['Trips_Approve']) && {
            key: '/apps/projects/meetings/trip/approval',
            icon: <SafetyOutlined />,
            label: (
              <Link to="/apps/projects/meetings/trip/approval">
                Trip Approvals
              </Link>
            ),
          },
        ].filter(Boolean),
      }
      : null,

    showFeature('HUMANRESOURCES') && allowIf(['Teams_Read'])
      ? {
        key: 'human_resources',
        icon: <UserOutlined />,
        label: 'Human Resources',
        children: [
          showFeature('LEAVES') && allowIf(["LeaveRequests_ReadWrite"])
            ? {
              key: 'leaves',
              icon: <CarryOutOutlined />,
              label: 'Leaves',
              children: [
                allowIf(['LeaveApprovals_ReadWrite']) && {
                  key: '/apps/leave/approval-list',
                  icon: <SafetyOutlined />,
                  label: (
                    <Link to="/apps/leave/approval-list">
                      Leave Approvals
                    </Link>
                  ),
                },
                {
                  key: '/apps/leave/list',
                  icon: <WalletOutlined />,
                  label: <Link to="/apps/leave/list">My Leaves</Link>,
                },
                allowIf(['LeaveHistory_ViewAll']) && {
                  key: '/apps/leave/history',
                  icon: <HistoryOutlined />,
                  label: (
                    <Link to="/apps/leave/history">Leave History</Link>
                  ),
                },
              ].filter(Boolean),
            }
            : null,
          {
            key: '/apps/human/teams',
            icon: <UsergroupAddOutlined />,
            label: <Link to="/apps/human/teams">Teams</Link>,
          },
        ].filter(Boolean),
      }
      : null,
  ].filter(Boolean) as MenuProps['items'];

  const items: MenuProps['items'] = [
    showFeature('TIMESHEET') && allowIf(['Timesheets_ReadWrite'])
      ? {
        key: 'add_timesheet',
        label: <Link to="apps/timesheet/create">Timesheet</Link>,
        icon: <UnorderedListOutlined />,
      }
      : null,

    showFeature('LEAVES') &&
      (allowIf(['LeaveHistory_View']) || allowIf(['Meetings_Read']))
      ? {
        key: 'add_leave',
        icon: <CalendarOutlined />,
        label: (
          <Typography onClick={() => setCreateModalOpen(true)}>
            Leave
          </Typography>
        ),
      }
      : null,

    showFeature('MEETING') &&
      (allowIf(['Meetings_Read']) || allowIf(['Trips_EditAll']))
      ? {
        key: 'add_projectMeeting',
        icon: <ClockCircleOutlined />,
        label: <Link to="apps/projects/meetings/create">Meeting</Link>,
      }
      : null,
    allowIf(['Meetings_Read']) || allowIf(['Trips_EditAll'])
      ? {
        key: 'add_trip',
        icon: <CarOutlined />,
        label: <Link to="apps/projects/meetings/trip/create">Trip</Link>,
      }
      : null,
  ].filter(Boolean);

  return (
    <Sider
      collapsedWidth={xs ? 0 : 120}
      width={xs ? 250 : 250}
      collapsible
      collapsed={collapsed}
      trigger={!xs && null}
      onCollapse={(value) => {
        xs ? setCollapsed(!value) : setCollapsed(value);
      }}
      className={`flex flex-col justify-between fixed h-[${windowDimensions.height}px] top-0 left-0 bottom-0 z-[10]`}
    >
      {isCreateModalOpen && (
        <BalanceProvider>
          <CalendarModalForm
            fetchData={fetchLeavesFunc}
            isOpen={isCreateModalOpen}
            setIsOpen={setCreateModalOpen}
            data={data}
            setData={setData}
          />
        </BalanceProvider>
      )}
      {openReport && (
        <ReportModal isOpen={openReport} onClose={() => setOpenReport(false)} />
      )}
      <div className="flex flex-col justify-start h-full">
        <div
          className={`flex pb-4 flex-row items-center ${collapsed ? 'justify-center mt-2' : 'mt-2'}`}
          ref={topLogoRef}
        >
          <div className="p-2 flex flex-col items-center w-full">
            <Button
              data-testid="reportBtn"
              onClick={() => setCollapsed(!collapsed)}
              className="!w-[50px] bg-[#1677ff] text-white border border-[#3c444b] rounded-[0.45rem]  hover:!bg-[#3887f5] hover:!border-[#3a4046] hover:!text-white"
              icon={
                !collapsed ? (
                  <ToTopOutlined style={{ transform: 'rotate(-90deg)' }} />
                ) : (
                  <MenuOutlined />
                )
              }
            ></Button>
            <div className="mt-6"></div>
            <SidebarLogo setCollapsed={setCollapsed} collapsed={collapsed} />
          </div>
        </div>

        {/* Scrollable content */}
        <SimpleBar
          forceVisible={true}
          autoHide={false}
          style={{ maxHeight: contentHeight }}
          className="px-4"
        >
          <Menu
            inlineIndent={10}
            theme="dark"
            selectedKeys={[current]}
            mode="inline"
            items={menuItems}
            onOpenChange={keys => setMenuOpenedKeys([...keys])}
            openKeys={menuOpenedKeys}
          />
        </SimpleBar>
        {/* <div
          className={`flex-grow overflow-hidden hover:overflow-y-scroll mt-5 [scrollbar-gutter:stable]`}>
          <Menu
            inlineIndent={10}
            theme="dark"
            selectedKeys={[current]}
            mode="inline"
            items={menuItems}
          />
        </div> */}
        {/* Report button at the bottom */}
        <div
          ref={footerRef}
          className="absolute bottom-0 left-0 w-[100%] z-30 h-[180px] flex flex-col justify-end items-end  p-2"
        >
          {items.length > 0 && (
            <div>
              <Dropdown menu={{ items }} className="mb-3">
                <Button
                  data-testid="addEntryBtn"
                  className="!w-[60px] bg-[#1677ff] text-white border border-[#3c444b] rounded-full h-[55px] hover:!bg-[#3887f5] hover:!border-[#3a4046] hover:!text-white"
                  style={{
                    width: '100%', // Adjust width based on collapse state
                    transform: 'translateX(30px)',
                  }}
                  icon={<PlusCircleOutlined size={64} />}
                ></Button>
              </Dropdown>
            </div>
          )}
          <div className="w-full">
            <Button
              data-testid="reportBtn"
              onClick={() => setOpenReport(true)}
              className="w-full bg-[#1677ff] text-white border border-[#3c444b] rounded-[0.45rem] h-[45px] hover:!bg-[#3887f5] hover:!border-[#3a4046] hover:!text-white"
              style={{
                width: '100%', // Adjust width based on collapse state
              }}
              icon={<BugFilled />}
            >
              {collapsed && !xs ? '' : 'Report an issue'}
            </Button>
          </div>
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
