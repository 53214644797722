import { Button, Divider, Input, Layout } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react'
import {
    UserOutlined, LockOutlined, EyeOutlined, EyeInvisibleOutlined
} from '@ant-design/icons';
import LoomInput from '../../../../components/StyledComponents/LoomInput';
import { login } from '../../../../api-requests/Auth';
import { useAuth } from '../../core/Auth';
import { useNotifications } from '../../../../permissions/components/timesheet/Notification-service';
import { AxiosError } from 'axios';
type Props = {}


const UsernameAndPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const { saveAuth } = useAuth();
    const { openNotification } = useNotifications();
    const toggleShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };
    const handleLogin = async (values: { username: string, password: string }) => {
        try {
            const response = await login(values);
            saveAuth(response.Token)
        } catch (err) {
            if (err instanceof AxiosError)
                openNotification('topRight', {
                    title: `Sign in`,
                    description: err?.response?.data.error,
                    type: 'Danger',
                });
        }
    }
    return (
        <Layout style={{ background: 'transparent' }} className='w-full'>
            <Formik
                initialValues={{ username: '', password: '' }}
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                    handleLogin(values)
                }}
            >
                {({ values, setFieldValue, handleSubmit, errors }) => (
                    <form onSubmit={handleSubmit} className=''>
                        <div className={`flex flex-col justify-center gap-4`}>
                            <div className="flex justify-center flex-col w-full gap-4">
                                <div
                                    data-testid="leaveTypeDropdown"
                                    className="w-full flex gap-2 flex-col"
                                >
                                    <LoomInput
                                        name="Name"
                                        placeholder="Username"
                                        prefix={<UserOutlined />}
                                        value={values.username}
                                        onChange={(e) => setFieldValue('username', e.target.value)}
                                        label={'Username'}
                                        disabled={false}
                                    />
                                </div>
                                <div
                                    data-testid="leaveTypeDropdown"
                                    className="w-full flex gap-2 flex-col"
                                >
                                    <LoomInput
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter password"
                                        prefix={<LockOutlined />}
                                        value={values.password}
                                        onChange={(e) => setFieldValue('password', e.target.value)}
                                        label={'Password'}
                                        disabled={false}
                                        suffix={
                                            <Button
                                                size='small'
                                                type="text"
                                                icon={showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                onClick={toggleShowPassword}
                                                style={{ border: 'none', background: 'transparent' }}
                                            />
                                        }
                                    />
                                    {/* <a>Forgot Password</a> */}

                                </div>
                            </div>
                            <Button type="primary" size={'large'} onClick={() => handleLogin(values)}>Sign in</Button>
                            <Divider>Or</Divider>
                        </div>

                    </form>
                )}
            </Formik>
        </Layout>
    )
}

export default UsernameAndPassword