import { useState, useEffect, RefObject } from 'react';

/**
 * Custom hook to calculate the remaining height for an element
 * after subtracting the combined height of multiple referenced elements.
 *
 * @param refs - An array of React ref objects pointing to the elements to measure
 * @returns The remaining height as a CSS `calc` string
 */
const useRemainingHeight = (refs: RefObject<HTMLElement>[]): string => {
  const [remainingHeight, setRemainingHeight] = useState<string>('100vh');

  useEffect(() => {
    const calculateHeight = () => {
      const totalHeight = refs.reduce((acc, ref) => {
        return acc + (ref.current?.offsetHeight || 0);
      }, 0);
      setRemainingHeight(`calc(100vh - ${totalHeight}px)`);
    };

    // Calculate height on mount and resize
    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, [refs]);

  return remainingHeight;
};

export default useRemainingHeight;
