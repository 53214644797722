import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { CommonListResponse } from '../@types/Response';
import {
  ProjectExtended,
  ProjectReporting,
  Report,
  ReportingPeriodAdd,
} from '../@types/Project';
import { ProjectSubmission } from '../@types/ProjectReporting';

const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const FETCH_PROJECTS = `${API_URL}/projects/list`;
export const FETCH_PROJECTS_PARTICIPATION = `${API_URL}/projects/my-project-participations`;
export const FETCH_PROJECT = `${API_URL}/projects/`;

export const FETCH_BENEFICIARIES = `${API_URL}/projects/beneficiaries`;
export const SUBMIT_COSTSTATEMENT = `${API_URL}/coststatements/submit`;
export const FETCH_COSTSTATEMENT = `${API_URL}/coststatements/`;
export const FETCH_COSTSTATEMENT_LIST = `${API_URL}/coststatements/list`;

export const SUBMIT_REPORTING_PERIOD = `${API_URL}/reportingperiods`;
export const DELETE_REPORTING_PERIOD = `${API_URL}/reportingperiods`;

export const FETCH_PARTICIPANTS_REPORTS = `${API_URL}/coststatements/participant-reports`;
export const APPROVE_COSTSTATEMENT = `${API_URL}/coststatements/approve`;
export const REJECT_COSTSTATEMENT = `${API_URL}/coststatements/reject`;
export const FETCH_REPORTING_PERIOD = `${API_URL}/projects/reporting-periods`;
export const GENERATE_REPORTS = `${API_URL}/coststatements/create-draft-periodic-reports`;
export interface FetchProjectsOptions {
  searchTerm?: string;
  pageIndex?: number;
  pageSize?: number;
  sorting?: string;
  orgId?: number;
  onlyActiveProjects?: boolean;
  projectId?: number | string;
  reportPeriodId?: number;
  coordinationsOnly?: boolean;
  status?: string;
  from?: string;
  to?: string;
  getFormalRPs?: boolean;
}

export const ReportingStatus = {
  0: { key: 'Draft', value: '#54A0FF' },
  1: {
    key: 'Submitted',
    value: '#54A0FF',
  },
  2: { key: 'Approved', value: '#30CB83' },
  3: { key: 'Rejected', value: '#B33771' },
} as const;
export const submitCostStatement = async (values: ProjectSubmission) => {
  try {
    const response = await axios.post<''>(SUBMIT_COSTSTATEMENT, values);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const generateCoststatements = async (data: {
  reportingPeriodId: number;
  notifyBeneficiaries?: boolean;
}) => {
  try {
    const response = await axios.post<''>(GENERATE_REPORTS, data);
    return response.data;
  } catch (error) {
    console.error('Error creating reports:', error);
    throw error;
  }
};
export const ApproveCoststatement = async (data: {
  id: number;
  comments: string;
}) => {
  try {
    const response = await axios.post<''>(APPROVE_COSTSTATEMENT, data);
    return response.data;
  } catch (error) {
    console.error('Error approving trip:', error);
    throw error;
  }
};
export const RejectCoststatement = async (data: {
  id: number;
  comments: string;
}) => {
  try {
    const response = await axios.post<''>(REJECT_COSTSTATEMENT, data);
    return response.data;
  } catch (error) {
    console.error('Error approving trip:', error);
    throw error;
  }
};
export const saveCostStatement = async (values: ProjectSubmission) => {
  try {
    const response = await axios.post<''>(FETCH_COSTSTATEMENT, values);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const saveReportingPeriod = async (values: ReportingPeriodAdd) => {
  try {
    const response = await axios.post<''>(SUBMIT_REPORTING_PERIOD, values);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const deleteReportingPeriod = async (id: number) => {
  try {
    const response = await axios.delete<''>(DELETE_REPORTING_PERIOD, {
      params: { Id: id },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const fetchReportingPeriod = async (data: {
  value: number;
  formal: boolean;
}) => {
  try {
    const params = new URLSearchParams({
      projectId: data.value.toString(),
      formal: String(data.formal),
    });
    const response = await axios.get<Report[]>(
      `${FETCH_REPORTING_PERIOD}?${params.toString()}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const fetchParticipantList = async ({
  searchTerm = '',
  pageIndex = 0,
  pageSize = 10,
  sorting = '',
  orgId = 0,
  onlyActiveProjects = true,
  projectId = 0,
  reportPeriodId = 0,
  from = '',
  to = '',
  getFormalRPs = false,
}: FetchProjectsOptions = {}) => {
  console.log('report', reportPeriodId);
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    orgId: orgId?.toString(),
    searchTerm,
    sorting,
    status,
    from,
    to,
    onlyActiveProjects: String(onlyActiveProjects.valueOf()),
    projectId: projectId.toString(),
    reportingPeriodId: String(reportPeriodId),
    getFormalRPs: String(getFormalRPs),
  });

  try {
    const response = await axios.get<CommonListResponse<any>>(
      `${FETCH_PARTICIPANTS_REPORTS}?${params.toString()}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export const fetchCostStatementById = async (id: number) => {
  try {
    const response = await axios.get<ProjectSubmission>(
      `${FETCH_COSTSTATEMENT}?id=` + id,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchProjectById = async (id: number) => {
  try {
    const response = await axios.get<ProjectExtended>(
      `${FETCH_PROJECT}?id=` + id,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const fetchProjects = async ({
  searchTerm = '',
  pageIndex = 0,
  pageSize = 10,
  sorting = '',
  orgId = 0,
  onlyActiveProjects = true,
}: FetchProjectsOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    orgId: orgId?.toString(),
    searchTerm,
    sorting,
    onlyActiveProjects: String(onlyActiveProjects.valueOf()),
  });

  try {
    const response = await axios.get<CommonListResponse<ProjectExtended>>(
      `${FETCH_PROJECTS}?${params.toString()}`,
    );

    return response?.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export const fetchProjectsParticipation = async ({
  searchTerm = '',
  pageIndex = 0,
  pageSize = 10,
  sorting = '',
  orgId = 0,
  status = '0,1,2,3',
  onlyActiveProjects = true,
  coordinationsOnly = false,
}: FetchProjectsOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    searchTerm,
    sorting,
    status,
    onlyActiveProjects: String(onlyActiveProjects.valueOf()),
    coordinationsOnly: String(coordinationsOnly.valueOf()),
  });

  try {
    const response = await axios.get<CommonListResponse<ProjectReporting>>(
      `${FETCH_PROJECTS_PARTICIPATION}?${params.toString()}`,
    );

    return response?.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export const fetchBeneficiaries = async ({
  projectId,
}: {
  projectId: number;
}) => {
  const params = new URLSearchParams({
    projectId: projectId.toString(),
  });

  try {
    const response = await axios.get<Beneficiary[]>(
      `${FETCH_BENEFICIARIES}?${params.toString()}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export const fetchPeriodicReports = async ({
  searchTerm = '',
  pageIndex = 0,
  pageSize = 10,
  sorting = '',
  onlyActiveProjects = true,
  status = '0,1,2,3',
}: FetchProjectsOptions = {}) => {
  const params = new URLSearchParams({
    pageSize: pageSize?.toString(),
    pageIndex: pageIndex?.toString(),
    searchTerm,
    sorting,
    onlyActiveProjects: String(onlyActiveProjects.valueOf()),
    status,
  });

  try {
    const response = await axios.get<CommonListResponse<ProjectReporting>>(
      `${FETCH_COSTSTATEMENT_LIST}?${params.toString()}`,
    );

    return response?.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};
export interface Beneficiary {
  Id: number;
  ParticipantNumber: string;
  ShortName: string;
  role: number;
  OtherDirectCosts_TravelCosts: number;
  Organisation: {
    Id: number;
    ShortName: string;
    LegalName: string;
    BusinessName: string;
    Country: {
      Id: number;
      Name: string;
      IsoCode2: string;
    };
  };
  Sponsor: string;
}
export interface Project {
  Id: number;
  Acronym: string;
  ProjectNumber?: string;
  Title?: string;
  StartDate?: string;
  Duration?: number;
  EndDate?: string;
  CurrentMonth: number;
  Progress: number;
}
