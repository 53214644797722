
import { TableProvider } from '../../table/provider/TableContext';
import { pageSelectOptions } from '../../../mock/PageSelection';
import { useTeam } from './provider/useTeam';
import TeamTable from './components/teamsTable';
import { Team } from '../../../@types/Team';
import { useEffect } from 'react';


const TeamTableWrapper = () => {
    const { teams, getTeams, teamsTotal, setTeams } = useTeam();


    useEffect(() => {
        return (() => {
            setTeams([]);
        })
    }, [])
    return (
        <TableProvider
            data={teams}
            defaultSort={[]}
            page={pageSelectOptions[0]}
            filters={[
            ]}
            actions={[getTeams]}
            totalSizeData={teamsTotal}
        >
            <TeamTable />
        </TableProvider>
    );
};

export default TeamTableWrapper;
