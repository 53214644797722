import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../helpers';
import { MutableRefObject } from 'react';
import { Divider, } from 'antd';

type PropsType = {
  sidebarRef?: MutableRefObject<HTMLDivElement | null>;
  setCollapsed: (d: boolean) => void;
  collapsed: boolean;
};

const SidebarLogo = (props: PropsType) => {
  return (
    <div className="app-sidebar-logo flex flex-col items-center w-full justify-center">
      <Link to="/dashboard">
        {!props.collapsed ? (
          <img
            data-testid="logoLg"
            alt="Logo"
            src={toAbsoluteUrl('media/logos/logo.png')}
            className="ps-3 h-[50px] app-sidebar-logo-default px-6"
          />
        ) : (
          <>
            <img
              data-testid="logoSm"
              alt="Logo"
              src={toAbsoluteUrl('media/logos/logo-sm.png')}
              className="w-40px h-[40px] app-sidebar-logo-minimize"
            />
          </>
        )
        }
      </Link>
    </div>
  );
};

export { SidebarLogo };
