import { createContext } from 'react';
import { FetchEmployeesOptions } from '../../../../@types/Employee';
import { Team } from '../../../../@types/Team';

export interface ITeamContext {
    teams: Team[];
    setTeams: (d: Team[]) => void;
    teamsTotal: number;
    loading: boolean;
    getTeams: (params: FetchEmployeesOptions) => void;
    createTeams: (values: Team) => void;
    deleteTeam: (id: number) => void;
}

const TeamContext = createContext<ITeamContext | undefined>(
    undefined,
);

TeamContext.displayName = 'Team';

export default TeamContext;
