import { FC } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import PageWrapper from '../components/wrapper/PageWrapper';
import TeamTableWrapper from '../features/humanResources/teams/teamsTableWrapper';
import Header from '../pages/layout/Header';
import ErrorsRoutes from './ErrorsRoutes';


const HumanResourcesRoutes: FC = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path="teams/"
                    element={
                        <>
                            <Header title="Teams">
                                <PageWrapper>
                                    <TeamTableWrapper />
                                </PageWrapper>
                            </Header>
                        </>
                    }
                />

            </Route>
            <Route path="/*" element={<ErrorsRoutes />} />
        </Routes>
    );
};

export default HumanResourcesRoutes;
