import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { LeaveRequestPost } from '../../../../@types/Request';
import { getWorkableDays } from '../../../../api-requests/Leave';
import CircularProgressWithIcon from '../../../../components/progress/CircularProgress';
import { useBalance } from '../../provider/useBalance';
import { BalanceData } from '../../../../@types/Balance';

type Props = {
  values: LeaveRequestPost;
  startDate: string;
  endDate: string;
  leaveBalance: BalanceData;

};

const LeaveFormStats = ({ values, startDate, endDate, leaveBalance }: Props) => {
  const { balance } = useBalance();
  const [workableDays, setWorkableDays] = useState(0);
  useEffect(() => {
    const func2 = async () => {
      if (startDate && endDate && leaveBalance.Policy.Country.ISOCode2) {
        if (leaveBalance) {
          await getWorkableDays(
            moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
            moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
            leaveBalance.Policy.Country.ISOCode2,
          ).then((res) => setWorkableDays(res));
        }

        else {
          if (balance) {
            await getWorkableDays(
              moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
              moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
              balance[0].Policy.Country.ISOCode2,
            ).then((res) => setWorkableDays(res));
          }

        }
      }
    };
    func2();
  }, [startDate, endDate]);

  if (leaveBalance)
    return (
      <div
        className="flex md:flex-row flex-col"
        style={{ alignItems: 'center', justifyContent: 'space-evenly', textAlign: 'center' }}
      >
        <div>
          <div>
            <CircularProgressWithIcon
              progress={
                leaveBalance.RemainingDays
              }
              divider={leaveBalance.LeaveQuota}
            />
          </div>
          <span>Available days</span>

        </div>
        <div
          className="flex flex-col justify-center items-center"
          style={{ fontSize: 32 }}
        >
          -
        </div>
        <div>
          <div>
            <CircularProgressWithIcon
              progress={workableDays}
              divider={leaveBalance.LeaveQuota}
            />
          </div>
          <span>Working days</span>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ fontSize: 32 }}
        >
          =
        </div>
        <div>
          <div>
            <CircularProgressWithIcon
              progress={
                leaveBalance.RemainingDays - workableDays
              }
              divider={leaveBalance.LeaveQuota}
            />
          </div>
          <span>New balance</span>
        </div>
      </div>
    );
};

export default LeaveFormStats;
