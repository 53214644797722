import { FC, ReactNode, ReactPortal, useMemo, useState } from 'react';
import { Beneficiary, fetchBeneficiaries } from '../../../api-requests/Projects';
import { LeaveOptions } from '../../../@types/FetchOptions';
import { Trip, TripApproval } from '../../../@types/Trip';
import { deleteTripById, fetchApprovalTrips, getTripList } from '../../../api-requests/Trip';
import TripContext from './tripContext';



type TUserProviderProps = {
    children: ReactNode | ReactPortal;
};

export const TripProvider: FC<TUserProviderProps> = ({ children }) => {
    const [trips, setTrips] = useState<Trip[]>([]);
    const [tripsTotal, setTripsTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);
    const [tripApprovals, setTripApprovals] = useState<TripApproval[]>([]);
    const [tripApprovalTotal, setTripApprovalTotal] = useState(0);
    const getTrip = async (params: LeaveOptions) => {
        setLoading(true);
        try {
            const meetingResponse = await getTripList({ ...params });
            setTrips(meetingResponse.Results);
            setTripsTotal(meetingResponse.TotalResults)
            return trips;
        } catch (error) {
            console.error('Error fetching trips:', error);
        } finally {
            setLoading(false);
        }
    };
    const getApprovalTrips = async (params: LeaveOptions) => {
        setLoading(true);
        try {
            const approvalResponse = await fetchApprovalTrips({ ...params });
            setTripApprovals(approvalResponse.Results);
            setTripApprovalTotal(approvalResponse.TotalResults);
            return approvalResponse.Results
        } catch (error) {
            console.error('Get Approval Trips', error);
            throw error;
        } finally {
            setLoading(false);
        }
    }
    const getBeneficiaries = async (projectId: number) => {
        setLoading(true);
        try {
            const ben = await fetchBeneficiaries({ projectId: projectId });
            setBeneficiaries(ben);
            return ben;
        } catch (error) {
            console.error('Error fetching beneficiaries:', error);
        } finally {
            setLoading(false);
        }
    }
    const handleDeleteTripById = async (id: number) => {
        setLoading(true);
        try {
            await deleteTripById(id);
            setTrips(trips.filter((trip) => trip.Id !== id));
        } catch (error) {
            console.error('Error deleting trip:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    }
    const contextValue = useMemo(
        () => ({
            trips,
            setTrips,
            getTrip,
            tripsTotal,
            loading,
            handleDeleteTripById,
            getBeneficiaries,
            beneficiaries,
            getApprovalTrips,
            tripApprovals,
            tripApprovalTotal,
            setTripApprovals
        }),
        [trips, loading, beneficiaries],
    );
    return (
        <TripContext.Provider value={contextValue} >
            {children}
        </TripContext.Provider>
    );
};
