

import { FC } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import { Logout, useAuth } from '../features/auth';
import { App } from '../App';
import Callback from '../features/auth/components/Callback';
import AuthRoutes from './AuthRoutes';

const AppRoutes: FC = () => {
  const { auth, permissions, allowIf } = useAuth();
  const location = useLocation();
  return (
    <Routes>
      <Route element={<App />}>
        {/* <Route path="error/*" element={<ErrorsRoutes />} /> */}
        <Route path="logout" element={<Logout />} />
        {auth && permissions.length > 0 ? (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            {allowIf(['Timesheets_ReadWrite', 'LeaveHistory_View']) ? <Route index element={<Navigate to="/dashboard" />} /> :
              <Route index element={<Navigate to="/apps/projects/report/list" />} />}
            {/* <Route index element={<Navigate to="/dashboard" />} /> */}
          </>
        ) : (
          <>
            <Route path="auth/callback/*" element={<Callback />} />
            <Route path="auth/*" element={<AuthRoutes />} />
            <Route path="*" element={<Navigate to="/auth" state={{ from: location }} />} />
          </>
        )}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
